<template>
  <div id="sessionSummaryContainer">
    <div class="header">
      <div class="titleWrapper">
        <p id="title">
          {{ `${$t("summary.title")} (${totalSessionsCount})` | capitalize }}
        </p>
      </div>
      <div id="btnGroup">
        <div
          class="icon"
          :class="{ disabled: shouldDisable }"
          @click="handleSummary('older')"
        >
          <img class="chevronLeft" src="../assets/icons/chevron_down.png" />
        </div>
        <div
          class="icon"
          :class="{ disabled: offset == 0 }"
          @click="handleSummary('newer')"
        >
          <img class="chevronRight" src="../assets/icons/chevron_down.png" />
        </div>
      </div>
    </div>
    <div id="summaryContainer" v-if="!summaryLoader">
      <div class="shadow"></div>
      <div class="overflow">
        <template v-for="session in summary">
          <div :key="session.id" class="helper">
            <div class="summaryData">
              <div class="wrapper">
                <div class="date semibold text item" v-if="session.date">
                  <p class="stat">
                    {{ session.date.day }} {{ session.date.month }}
                  </p>
                </div>
                <div class="semibold text item">
                  <p class="stat">
                    {{ parseStat(session.distance, "distance", $i18n.locale) }}
                  </p>
                  <p class="statText">{{ $t("summary.distance_unit") }}</p>
                </div>
                <div class="semibold text item">
                  <p class="stat">
                    {{ parseStat(session.laps, "laps", $i18n.locale) }}
                  </p>
                  <p class="statText">
                    {{ $t("summary.laps_unit") | capitalize }}
                  </p>
                </div>
                <div class="semibold text item">
                  <p class="stat">
                    {{ parseStat(session.speed, "speed", $i18n.locale) }}
                  </p>
                  <p class="statText">{{ $t("summary.speed_unit") }}</p>
                </div>
                <div class="semibold text item" v-if="!isDesktop">
                  <p class="stat">
                    {{
                      parseStat(
                        session.sessionTime,
                        "sessionTime",
                        $i18n.locale
                      )
                    }}
                  </p>
                  <p class="statText">
                    {{ $t("summary.sessionTime_unit") | capitalize }}
                  </p>
                </div>
                <div class="semibold text item" v-if="!isDesktop">
                  <p class="stat">
                    {{ parseStat(session.swimTime, "swimTime", $i18n.locale) }}
                  </p>
                  <p class="statText">
                    {{ $t("summary.swimTime_unit") | capitalize }}
                  </p>
                </div>
                <div class="semibold text item" v-if="!isDesktop">
                  <p class="stat">
                    {{
                      parseStat(
                        session.restingTime,
                        "restingTime",
                        $i18n.locale
                      )
                    }}
                  </p>
                  <p class="statText">
                    {{ $t("summary.restingTime_unit") | capitalize }}
                  </p>
                </div>
              </div>
              <div
                class="moreInfoBtn"
                v-if="isDesktop"
                @click="session.moreInfo = !session.moreInfo"
              >
                <p>{{ infoState(session.moreInfo) | capitalize }}</p>
              </div>
            </div>
            <div v-if="session.moreInfo" id="conditionalInfo">
              <div class="condStatWrapper">
                <p class="condStat">
                  {{ parseStat(session.sessionTime, "sessionTime") }}
                </p>
                <p class="condStatText">
                  {{ $t("summary.sessionTime_unit") | capitalize }}
                </p>
              </div>
              <div class="condStatWrapper">
                <p class="condStat">
                  {{ parseStat(session.swimTime, "swimTime") }}
                </p>
                <p class="condStatText">
                  {{ $t("summary.swimTime_unit") | capitalize }}
                </p>
              </div>
              <div class="condStatWrapper">
                <p class="condStat">
                  {{ parseStat(session.restingTime, "restingTime") }}
                </p>
                <p class="condStatText">
                  {{ $t("summary.restingTime_unit") | capitalize }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <LoadingDots :black="true" v-if="summaryLoader" class="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isDesktop, parseStat } from "../parsers";

const LoadingDots = () => import("./LoadingDots");

export default {
  name: "Summary",
  components: { LoadingDots },
  data() {
    return {
      isDesktop: false,
      offset: 0,
      limit: 3,
    };
  },
  created() {
    this.isDesktop = isDesktop();
  },
  computed: {
    ...mapState({
      shouldDisable: (state) => state.disableButton,
      summaryLoader: (state) => state.summaryLoader,
      summaryRequest: (state) => state.summaryRequest,
      summary: (state) => state.summary,
      totalSessionsCount: (state) => state.totalSessionsCount,
    }),
  },
  methods: {
    infoState(dropdown) {
      if (!dropdown) return this.$t("summary.more_info");
      return this.$t("summary.less_info");
    },
    handleSummary(value) {
      if (!this.summaryRequest) {
        this.$store.dispatch("summaryRequesting", true);
        this.$store.dispatch("summaryLoading", true);
        this.offset =
          value == "newer"
            ? Math.max(0, (this.offset -= this.limit))
            : this.offset + 3;

        this.$store.dispatch("fetchSummary", this.offset);
      }
    },
    parseStat,
  },
};
</script>

<style scoped>
#sessionSummaryContainer {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px 0px #ccd0db;
  background-color: white;
}
.shadow {
  display: none;
}
#summaryContainer .helper:nth-child(2) {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.summaryData {
  height: 100%;
}
.helper {
  height: 5.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}
.text {
  display: flex;
  align-items: center;
  color: black;
}
.semibold {
  font-weight: 500;
}
.semibold {
  font-size: 1.5rem;
}
.date {
  max-width: 12%;
  font-size: 1rem;
}
.statText {
  padding-left: 0.5em;
  font-weight: 300;
  font-size: 1rem;
}
.item {
  min-width: 7.5rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end !important;
}
#title {
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 3rem;
  margin-bottom: 0;
  text-align: left;
}
#btnGroup {
  display: flex;
  justify-self: center;
  align-content: center;
  padding: 0 2.5rem;
}
.icon {
  height: 2rem;
  width: auto;
  justify-content: center;
  display: flex;
}
.icon img {
  max-width: 100%;
  max-height: 100%;
}
.chevronLeft {
  margin-right: 1em;
  transform: rotate(90deg);
}
.chevronRight {
  transform: scaleX(-1) rotate(90deg);
}
.chevronRight:hover,
.chevronLeft:hover {
  cursor: pointer;
}
.loading {
  height: 5rem;
  width: 100%;
  margin: auto;
  font-size: 5rem !important;
}
.loading {
  color: black !important;
}
#summaryContainer {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  justify-content: space-around;
}
.summaryData {
  display: flex;
  justify-content: center;
}
.wrapper {
  display: flex;
}
.wrapper p {
  margin: 0;
}
#button {
  color: black;
}
.moreInfoBtn {
  display: flex;
  width: fit-content;
  margin-left: auto;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  color: red;
}
#conditionalInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.75rem;
  color: black;
  background-color: #eef1f3;
}
.condStatWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.condStatWrapper p {
  margin: 0 0.2em;
}
.condStatText {
  font-weight: 300;
}
.condStat {
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  #summaryContainer {
    padding: 0 0 0 6%;
    overflow-x: hidden;
    position: relative;
  }
  #summaryContainer .helper:nth-child(2) {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .overflow {
    overflow-x: scroll;
    z-index: 1;
    margin-right: 2px;
  }
  .shadow {
    display: block;
    box-shadow: inset -30px 20px 30px rgba(255, 255, 255, 1);
    height: 100%;
    width: 3rem;
    right: 0;
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }
  .summaryData {
    padding-top: 3%;
  }
  .header {
    display: flex;
    padding: 0 6%;
  }
  .icon {
    background-color: #030d2c;
    margin: auto 1px;
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon img {
    filter: invert(100%);
    height: 25px;
    width: 25px;
    margin: unset;
  }
  .disabled {
    opacity: 0.1;
  }
  .titleWrapper #title,
  #btnGroup {
    padding: 0;
  }
  .helper,
  .item *:not(.moreInfoBtn) {
    font-size: 1.2rem;
  }
  .wrapper .item:nth-child(2) {
    padding-left: 0;
  }
  .item {
    min-width: 6rem;
    padding: 0 1.2rem;
  }
  .wrapper .item:last-child {
    padding-right: 2rem;
  }
  .item p {
    white-space: nowrap;
  }
  .helper {
    position: relative;
    width: fit-content;
    justify-content: center;
  }
  .date {
    padding-left: 0;
    position: absolute;
    top: 20%;
    left: 0;
  }
  .date p {
    font-size: 0.875rem !important;
  }
  .helper {
    height: 6.2rem;
  }
}
</style>
